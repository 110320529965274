var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-dialog",
    {
      attrs: { "max-width": "600" },
      model: {
        value: _vm.dialog,
        callback: function ($$v) {
          _vm.dialog = $$v
        },
        expression: "dialog",
      },
    },
    [
      _c(
        "v-card",
        [
          _c(
            "v-card-title",
            {
              staticClass: "wc-SimpleHeader pa-0 lh-l pl-4",
              attrs: { height: "56px", color: _vm.ColorGray2 },
            },
            [
              _vm._v("\n      作成履歴\n      "),
              _c("v-spacer"),
              _c(
                "v-icon",
                { staticClass: "mr-3", on: { click: _vm.closeHistoryDialog } },
                [_vm._v("\n        close\n      ")]
              ),
            ],
            1
          ),
          _c(
            "v-card-text",
            { staticClass: "fs-xs" },
            [
              _vm.loading
                ? _c(
                    "div",
                    [
                      _c("v-skeleton-loader", {
                        attrs: { type: "table", loading: _vm.loading },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.historyList.length === 0 && !_vm.loading
                ? _c("v-simple-table", {
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function () {
                            return [
                              _c("thead", [
                                _c("tr", [
                                  _c("th", { staticClass: "text-center" }, [
                                    _vm._v("履歴ID"),
                                  ]),
                                  _c("th", { staticClass: "text-center" }, [
                                    _vm._v("アイテム名"),
                                  ]),
                                  _c("th", { staticClass: "text-center" }, [
                                    _vm._v("作成日時"),
                                  ]),
                                ]),
                              ]),
                              _c("tbody", [
                                _c("tr", [
                                  _c(
                                    "td",
                                    {
                                      staticClass: "text-center",
                                      attrs: { colspan: "3" },
                                    },
                                    [_vm._v("データがありません")]
                                  ),
                                ]),
                              ]),
                            ]
                          },
                          proxy: true,
                        },
                      ],
                      null,
                      false,
                      3811946239
                    ),
                  })
                : _c("v-simple-table", {
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function () {
                          return [
                            _c("thead", [
                              _c("tr", [
                                _c("th", { staticClass: "text-center" }, [
                                  _vm._v("履歴ID"),
                                ]),
                                _c("th", { staticClass: "text-center" }, [
                                  _vm._v("アイテム名"),
                                ]),
                                _c("th", { staticClass: "text-center" }, [
                                  _vm._v("作成日時"),
                                ]),
                              ]),
                            ]),
                            _c(
                              "tbody",
                              _vm._l(_vm.historyList, function (
                                history,
                                index
                              ) {
                                return _c("tr", { key: index }, [
                                  _c("td", { staticClass: "text-center" }, [
                                    _vm._v(_vm._s(history.itemSynthHistoryId)),
                                  ]),
                                  _c("td", { staticClass: "text-center" }, [
                                    _vm._v(_vm._s(history.itemName)),
                                  ]),
                                  _c("td", { staticClass: "text-center" }, [
                                    _vm._v(
                                      _vm._s(_vm.moment(history.createdAt))
                                    ),
                                  ]),
                                ])
                              }),
                              0
                            ),
                          ]
                        },
                        proxy: true,
                      },
                    ]),
                  }),
              _c("v-pagination", {
                staticClass: "sl-Pagination",
                attrs: {
                  color: "var(--gray-6)",
                  circle: "",
                  length: Math.ceil(_vm.count / _vm.size),
                },
                on: {
                  input: function ($event) {
                    return _vm.getHistory(_vm.page, _vm.size)
                  },
                },
                model: {
                  value: _vm.page,
                  callback: function ($$v) {
                    _vm.page = $$v
                  },
                  expression: "page",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }