var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "pSignup" },
    [
      _c(
        "div",
        { staticClass: "pSignup-Wrap" },
        [
          _c("div", { staticClass: "pSignup-Copy_Main" }, [
            _vm._v("みんなでつくり、みんなと生きる物語。"),
          ]),
          _vm._m(0),
          _c(
            "v-slide-x-reverse-transition",
            { attrs: { "hide-on-leave": "" } },
            [
              _vm.logType === "signup"
                ? _c(
                    "div",
                    {
                      staticClass: "pSignup-Form",
                      on: {
                        keydown: function ($event) {
                          if (
                            !$event.type.indexOf("key") &&
                            _vm._k(
                              $event.keyCode,
                              "enter",
                              13,
                              $event.key,
                              "Enter"
                            )
                          )
                            return null
                          $event.stopPropagation()
                          return _vm.signUpMail.apply(null, arguments)
                        },
                      },
                    },
                    [
                      _c("div", { staticClass: "pSignup-Form_Unit" }, [
                        _c("div", { staticClass: "pSignup-Form_Label" }, [
                          _vm._v("メールアドレス"),
                        ]),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.mailaddress,
                              expression: "mailaddress",
                            },
                          ],
                          staticClass: "pSignup-Form_Input",
                          attrs: {
                            outlined: "",
                            type: "text",
                            autocomplete: "username",
                          },
                          domProps: { value: _vm.mailaddress },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) return
                              _vm.mailaddress = $event.target.value
                            },
                          },
                        }),
                      ]),
                      _c(
                        "v-fade-transition",
                        [
                          _vm.errorMsg
                            ? _c(
                                "v-alert",
                                {
                                  attrs: {
                                    transition: "slide-x-transition",
                                    dense: "",
                                    outlined: "",
                                    icon: "error_outline",
                                    type: "error",
                                  },
                                },
                                [_vm._v(_vm._s(_vm.errorMsg))]
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "pSignup-Form_Btn" },
                        [
                          _c(
                            "v-btn",
                            {
                              attrs: {
                                block: "",
                                large: "",
                                depressed: "",
                                dark: "",
                                color: _vm.ColorPrimary,
                                loading: _vm.loadingBtn,
                              },
                              on: { click: _vm.signUpMail },
                            },
                            [_vm._v("ユーザー登録")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ]
          ),
          _c(
            "v-slide-x-reverse-transition",
            { attrs: { "hide-on-leave": "" } },
            [
              _vm.logType === "passwordReset"
                ? _c(
                    "div",
                    {
                      staticClass: "pSignup-Form",
                      on: {
                        keydown: function ($event) {
                          if (
                            !$event.type.indexOf("key") &&
                            _vm._k(
                              $event.keyCode,
                              "enter",
                              13,
                              $event.key,
                              "Enter"
                            )
                          )
                            return null
                          $event.stopPropagation()
                          return _vm.passwordReset.apply(null, arguments)
                        },
                      },
                    },
                    [
                      _c("div", { staticClass: "pSignup-Form_Unit" }, [
                        _c("div", { staticClass: "fs-s my-8 text-center" }, [
                          _vm._v("\n            パスワードの"),
                          _c("span", { staticClass: "font-weight-bold" }, [
                            _vm._v("再設定メール"),
                          ]),
                          _vm._v("をお送りします。"),
                          _c("br"),
                          _vm._v(
                            "ご登録のメールアドレスを入力してください。\n          "
                          ),
                        ]),
                        _c("div", { staticClass: "pSignup-Form_Label" }, [
                          _vm._v("ご登録メールアドレス"),
                        ]),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.mailaddress,
                              expression: "mailaddress",
                            },
                          ],
                          staticClass: "pSignup-Form_Input",
                          attrs: {
                            outlined: "",
                            type: "text",
                            autocomplete: "username",
                          },
                          domProps: { value: _vm.mailaddress },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) return
                              _vm.mailaddress = $event.target.value
                            },
                          },
                        }),
                      ]),
                      _c(
                        "v-fade-transition",
                        [
                          _vm.errorMsg
                            ? _c(
                                "v-alert",
                                {
                                  attrs: {
                                    transition: "slide-x-transition",
                                    dense: "",
                                    outlined: "",
                                    icon: "error_outline",
                                    type: "error",
                                  },
                                },
                                [_vm._v(_vm._s(_vm.errorMsg))]
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "pSignup-Form_Btn" },
                        [
                          _c(
                            "v-btn",
                            {
                              attrs: {
                                block: "",
                                large: "",
                                depressed: "",
                                dark: "",
                                color: _vm.ColorPrimary,
                                loading: _vm.loadingBtn,
                              },
                              on: { click: _vm.passwordReset },
                            },
                            [_vm._v("送信")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ]
          ),
          _c(
            "v-slide-x-reverse-transition",
            { attrs: { "hide-on-leave": "" } },
            [
              _vm.logType === "sendedResetMail"
                ? _c("div", [
                    _vm._v("\n        メールを送信しました。\n      "),
                  ])
                : _vm._e(),
            ]
          ),
          _c(
            "v-slide-x-reverse-transition",
            { attrs: { "hide-on-leave": "" } },
            [
              _vm.logType === "signin"
                ? _c(
                    "div",
                    {
                      staticClass: "pSignup-Form",
                      on: {
                        keydown: function ($event) {
                          if (
                            !$event.type.indexOf("key") &&
                            _vm._k(
                              $event.keyCode,
                              "enter",
                              13,
                              $event.key,
                              "Enter"
                            )
                          )
                            return null
                          $event.stopPropagation()
                          return _vm.signInMail.apply(null, arguments)
                        },
                      },
                    },
                    [
                      _vm.sendedMailaddress
                        ? _c(
                            "div",
                            {
                              staticClass: "my-8 text-center font-weight-bold",
                            },
                            [
                              _c("span", { staticClass: "fs-l pr-1" }, [
                                _vm._v(_vm._s(_vm.sendedMailaddress)),
                              ]),
                              _c("span", { staticClass: "fs-s" }, [
                                _vm._v("宛に"),
                              ]),
                              _c("br"),
                              _vm.msgflg == 1
                                ? _c("span", { staticClass: "fs-s" }, [
                                    _vm._v("登録案内のメールを送信しました。"),
                                  ])
                                : _vm.msgflg == 2
                                ? _c("span", { staticClass: "fs-s" }, [
                                    _vm._v(
                                      "パスワード再設定メールを送信しました。"
                                    ),
                                  ])
                                : _vm._e(),
                            ]
                          )
                        : _vm._e(),
                      _c("div", { staticClass: "pSignup-Form_Unit" }, [
                        _c("div", { staticClass: "pSignup-Form_Label" }, [
                          _vm._v("メールアドレス"),
                        ]),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.mailaddress,
                              expression: "mailaddress",
                            },
                          ],
                          staticClass: "pSignup-Form_Input",
                          attrs: {
                            outlined: "",
                            type: "text",
                            autocomplete: "username",
                          },
                          domProps: { value: _vm.mailaddress },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) return
                              _vm.mailaddress = $event.target.value
                            },
                          },
                        }),
                      ]),
                      _c("div", { staticClass: "pSignup-Form_Unit" }, [
                        _c("div", { staticClass: "pSignup-Form_Label" }, [
                          _vm._v("パスワード"),
                        ]),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.password,
                              expression: "password",
                            },
                          ],
                          staticClass: "pSignup-Form_Input",
                          attrs: {
                            outlined: "",
                            type: "password",
                            autocomplete: "current-password",
                          },
                          domProps: { value: _vm.password },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) return
                              _vm.password = $event.target.value
                            },
                          },
                        }),
                        _c(
                          "div",
                          { staticClass: "text-right" },
                          [
                            _c(
                              "v-btn",
                              {
                                staticClass: "logSwitch",
                                attrs: { text: "", small: "" },
                                on: {
                                  click: function ($event) {
                                    _vm.logType = "passwordReset"
                                    _vm.sendedMailaddress = ""
                                  },
                                },
                              },
                              [_vm._v("パスワードがわからない")]
                            ),
                          ],
                          1
                        ),
                      ]),
                      _c(
                        "v-fade-transition",
                        [
                          _vm.errorMsg
                            ? _c(
                                "v-alert",
                                {
                                  attrs: {
                                    transition: "slide-x-transition",
                                    dense: "",
                                    outlined: "",
                                    icon: "error_outline",
                                    type: "error",
                                  },
                                },
                                [_vm._v(_vm._s(_vm.errorMsg))]
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "pSignup-Form_Btn" },
                        [
                          _c(
                            "v-btn",
                            {
                              attrs: {
                                block: "",
                                large: "",
                                depressed: "",
                                dark: "",
                                color: _vm.ColorPrimary,
                                loading: _vm.loadingBtn,
                              },
                              on: { click: _vm.signInMail },
                            },
                            [_vm._v("ログイン")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ]
          ),
          _c("div", { staticClass: "textCenter socialBtnArea" }, [
            _c("div", { staticClass: "text-center" }, [
              _vm.logType === "signup"
                ? _c(
                    "span",
                    { staticClass: "textFont" },
                    [
                      _vm._v("\n          登録済みの方は\n          "),
                      _c(
                        "v-btn",
                        {
                          attrs: { text: "" },
                          on: {
                            click: function ($event) {
                              _vm.logType = "signin"
                              _vm.sendedMailaddress = ""
                            },
                          },
                        },
                        [_vm._v("ログイン")]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.logType === "signin" || _vm.logType === "passwordReset"
                ? _c(
                    "span",
                    { staticClass: "textFont" },
                    [
                      _vm._v("\n          未登録の方は\n          "),
                      _c(
                        "v-btn",
                        {
                          staticClass: "px-2 fs-m fc-primary",
                          attrs: { text: "" },
                          on: {
                            click: function ($event) {
                              _vm.logType = "signup"
                              _vm.sendedMailaddress = ""
                            },
                          },
                        },
                        [_vm._v("ユーザー登録")]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ]),
            _c("br"),
            _c("br"),
            _c("br"),
          ]),
          _c("SpecialThanks"),
        ],
        1
      ),
      _c(
        "CommonDialog",
        { ref: "commonDialog", attrs: { title: _vm.dialogTitle } },
        [_c("p", { staticClass: "mb-0" }, [_vm._v(_vm._s(_vm.dialogMsg))])]
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "pSignup-Copy_Sub" }, [
      _vm._v("\n      アルパカコネクトで\n      "),
      _c("br"),
      _vm._v("PBW\n      "),
      _c("span", { staticClass: "pSignup-Copy_Sub-ruby" }, [
        _vm._v("(プレイ・バイ・ウェブ)"),
      ]),
      _vm._v("を、はじめよう。\n    "),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }