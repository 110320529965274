var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "pSignup" },
    [
      _c("ValidationObserver", {
        ref: "obs",
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function ({ invalid }) {
              return [
                _c(
                  "div",
                  { staticClass: "pSignup-Wrap" },
                  [
                    _c("div", { staticClass: "pSignup-Copy_Sub" }, [
                      _c("br"),
                      _c("span", {
                        staticClass: "pSignup-Copy_Sub-ruby",
                        domProps: { innerHTML: _vm._s(_vm.msg) },
                      }),
                    ]),
                    _c(
                      "v-form",
                      { staticClass: "pSignup-Form" },
                      [
                        _c(
                          "v-card",
                          { attrs: { outlined: "" } },
                          [
                            _c(
                              "v-window",
                              {
                                model: {
                                  value: _vm.step,
                                  callback: function ($$v) {
                                    _vm.step = $$v
                                  },
                                  expression: "step",
                                },
                              },
                              [
                                _c(
                                  "v-window-item",
                                  { attrs: { value: 1 } },
                                  [
                                    _c("v-card-text", [
                                      _c(
                                        "div",
                                        { staticClass: "pSignup-Form_Unit" },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass: "pSignup-Form_Label",
                                            },
                                            [_vm._v("メールアドレス")]
                                          ),
                                          _c(
                                            "div",
                                            { staticClass: "pt-1 pb-4" },
                                            [_vm._v(_vm._s(_vm.mailaddress))]
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "div",
                                        { staticClass: "pSignup-Form_Unit" },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass: "pSignup-Form_Label",
                                            },
                                            [
                                              _vm._v(
                                                "\n                    パスワード（半角英数字、6〜72文字）\n                  "
                                              ),
                                            ]
                                          ),
                                          _c("ValidationProvider", {
                                            attrs: {
                                              mode: "eager",
                                              rules: {
                                                required: true,
                                                min: 6,
                                                max: 72,
                                                regex: /^[a-zA-Z0-9!#$%&()*+,.:;=?@\[\]\|^_{}~“’<>\/\\-]+$/,
                                              },
                                              name: "パスワード",
                                              vid: "password",
                                            },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "default",
                                                  fn: function ({ errors }) {
                                                    return [
                                                      _c("v-text-field", {
                                                        attrs: {
                                                          outlined: "",
                                                          dense: "",
                                                          "append-icon": _vm.show1
                                                            ? "visibility"
                                                            : "visibility_off",
                                                          type: _vm.show1
                                                            ? "text"
                                                            : "password",
                                                          "hide-details":
                                                            errors.length === 0,
                                                          "error-messages": errors,
                                                        },
                                                        on: {
                                                          "click:append": function (
                                                            $event
                                                          ) {
                                                            _vm.show1 = !_vm.show1
                                                          },
                                                        },
                                                        model: {
                                                          value: _vm.password,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.password = $$v
                                                          },
                                                          expression:
                                                            "password",
                                                        },
                                                      }),
                                                    ]
                                                  },
                                                },
                                              ],
                                              null,
                                              true
                                            ),
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "div",
                                        {
                                          staticClass: "pSignup-Form_Unit mt-4",
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass: "pSignup-Form_Label",
                                            },
                                            [_vm._v("パスワード（確認）")]
                                          ),
                                          _c("ValidationProvider", {
                                            key: "1",
                                            attrs: {
                                              rules:
                                                "required|confirmed:password",
                                              name: "パスワード（確認）",
                                            },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "default",
                                                  fn: function ({ errors }) {
                                                    return [
                                                      _c("v-text-field", {
                                                        attrs: {
                                                          outlined: "",
                                                          dense: "",
                                                          "append-icon": _vm.show2
                                                            ? "visibility"
                                                            : "visibility_off",
                                                          type: _vm.show2
                                                            ? "text"
                                                            : "password",
                                                          "hide-details":
                                                            errors.length === 0,
                                                          "error-messages": errors,
                                                        },
                                                        on: {
                                                          "click:append": function (
                                                            $event
                                                          ) {
                                                            _vm.show2 = !_vm.show2
                                                          },
                                                        },
                                                        model: {
                                                          value: _vm.repassword,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.repassword = $$v
                                                          },
                                                          expression:
                                                            "repassword",
                                                        },
                                                      }),
                                                    ]
                                                  },
                                                },
                                              ],
                                              null,
                                              true
                                            ),
                                          }),
                                        ],
                                        1
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                                _c(
                                  "v-window-item",
                                  { attrs: { value: 2 } },
                                  [
                                    _c(
                                      "v-list",
                                      { staticClass: "pt-8" },
                                      [
                                        _vm._l(_vm.userClassList, function (
                                          item,
                                          index
                                        ) {
                                          return [
                                            _c(
                                              "v-list-item",
                                              {
                                                key: index,
                                                staticClass: "px-2 px-sm-4",
                                                attrs: { disabled: index != 0 },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.nextpage(
                                                      _vm.step,
                                                      index
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _c(
                                                  "v-list-item-avatar",
                                                  {
                                                    attrs: {
                                                      size: "50",
                                                      color: _vm.ColorGray2,
                                                    },
                                                  },
                                                  [
                                                    _c("v-img", {
                                                      attrs: {
                                                        src: `${_vm.assetsPath}/p/img/class/classIcon_${item.id}.png`,
                                                        width: "50",
                                                        "aspect-ratio": "1",
                                                        id: "img",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "v-list-item-content",
                                                  [
                                                    _c(
                                                      "v-list-item-title",
                                                      {
                                                        staticClass:
                                                          "font-weight-bold",
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(item.className)
                                                        ),
                                                      ]
                                                    ),
                                                    _c(
                                                      "v-list-item-content",
                                                      {
                                                        staticClass:
                                                          "fs-xs pt-1 pb-0",
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(item.classText)
                                                        ),
                                                      ]
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                            _vm.userClassList.length != index
                                              ? _c("v-divider", {
                                                  key: index + 0.5,
                                                  staticClass: "mx-8",
                                                })
                                              : _vm._e(),
                                          ]
                                        }),
                                        _c("v-list-item", [
                                          _c(
                                            "div",
                                            { staticClass: "fs-xs px-2" },
                                            [
                                              _vm._v(
                                                "\n                    ※クリエイター・マスター審査は別途お申し込みを受け付けております。詳しくは"
                                              ),
                                              _c(
                                                "a",
                                                {
                                                  attrs: {
                                                    href:
                                                      "https://alpaca-connect.com/recruitingForm",
                                                    target: "_blank",
                                                  },
                                                },
                                                [_vm._v("こちら")]
                                              ),
                                            ]
                                          ),
                                        ]),
                                      ],
                                      2
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "v-window-item",
                                  { attrs: { value: 3 } },
                                  [
                                    _vm.loading
                                      ? _c(
                                          "div",
                                          {
                                            staticClass:
                                              "d-flex justify-center py-10 my-10",
                                          },
                                          [
                                            _c("v-progress-circular", {
                                              attrs: {
                                                size: 80,
                                                color: "primary",
                                                indeterminate: "",
                                              },
                                            }),
                                          ],
                                          1
                                        )
                                      : [
                                          _c(
                                            "v-card-text",
                                            [
                                              _c(
                                                "div",
                                                { staticClass: "fs-xs" },
                                                [_vm._v("プレイヤー名")]
                                              ),
                                              _c("ValidationProvider", {
                                                attrs: {
                                                  rules: "required|max:16",
                                                  name: "プレイヤー名",
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "default",
                                                      fn: function ({
                                                        errors,
                                                      }) {
                                                        return [
                                                          _c("v-text-field", {
                                                            attrs: {
                                                              outlined: "",
                                                              dense: "",
                                                              width: "100px",
                                                              "error-messages": errors,
                                                            },
                                                            model: {
                                                              value:
                                                                _vm.userName,
                                                              callback: function (
                                                                $$v
                                                              ) {
                                                                _vm.userName = $$v
                                                              },
                                                              expression:
                                                                "userName",
                                                            },
                                                          }),
                                                        ]
                                                      },
                                                    },
                                                  ],
                                                  null,
                                                  true
                                                ),
                                              }),
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "pSignup-Form_Unit",
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "pSignup-Form_Label",
                                                    },
                                                    [_vm._v("生年月日")]
                                                  ),
                                                  _c("DateSelect", {
                                                    staticClass:
                                                      "mt-2 dataSelect",
                                                    on: {
                                                      dateChange:
                                                        _vm.dateChange,
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "pSignup-Form_Unit",
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "pSignup-Form_Label",
                                                    },
                                                    [_vm._v("性別")]
                                                  ),
                                                  _c(
                                                    "ValidationProvider",
                                                    {
                                                      attrs: {
                                                        rules: "required",
                                                        name: "規約同意",
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "v-radio-group",
                                                        {
                                                          model: {
                                                            value:
                                                              _vm.selectedSex,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.selectedSex = $$v
                                                            },
                                                            expression:
                                                              "selectedSex",
                                                          },
                                                        },
                                                        _vm._l(
                                                          _vm.sexOptions,
                                                          function (sexOption) {
                                                            return _c(
                                                              "v-radio",
                                                              {
                                                                key:
                                                                  sexOption.value,
                                                                attrs: {
                                                                  label:
                                                                    sexOption.text,
                                                                  value:
                                                                    sexOption.value,
                                                                },
                                                              }
                                                            )
                                                          }
                                                        ),
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "div",
                                                {},
                                                [
                                                  _c(
                                                    "ValidationProvider",
                                                    {
                                                      attrs: {
                                                        rules: "required",
                                                        name: "規約同意",
                                                      },
                                                    },
                                                    [
                                                      _c("v-checkbox", {
                                                        attrs: {
                                                          value: "checked",
                                                          "unchecked-value":
                                                            "not_checked",
                                                        },
                                                        scopedSlots: _vm._u(
                                                          [
                                                            {
                                                              key: "label",
                                                              fn: function () {
                                                                return [
                                                                  _c(
                                                                    "span",
                                                                    {
                                                                      staticClass:
                                                                        "fs-s",
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "a",
                                                                        {
                                                                          staticClass:
                                                                            "mx-2",
                                                                          attrs: {
                                                                            href:
                                                                              "https://support.alpaca-connect.com/hc/ja/articles/360044065791",
                                                                            target:
                                                                              "_blank",
                                                                            rel:
                                                                              "noopener noreferrer",
                                                                          },
                                                                          on: {
                                                                            click: function (
                                                                              $event
                                                                            ) {
                                                                              $event.stopPropagation()
                                                                            },
                                                                          },
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            "利用規約"
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ]
                                                                  ),
                                                                  _vm._v(
                                                                    "に同意する\n                        "
                                                                  ),
                                                                ]
                                                              },
                                                              proxy: true,
                                                            },
                                                          ],
                                                          null,
                                                          true
                                                        ),
                                                        model: {
                                                          value:
                                                            _vm.riyouKiyaku,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.riyouKiyaku = $$v
                                                          },
                                                          expression:
                                                            "riyouKiyaku",
                                                        },
                                                      }),
                                                      _vm._v(
                                                        "\n                      " +
                                                          _vm._s(
                                                            _vm.$refs
                                                              .obsPlayerName
                                                          ) +
                                                          "\n                    "
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                  ],
                                  2
                                ),
                                _c(
                                  "v-window-item",
                                  { attrs: { value: 4 } },
                                  [
                                    [
                                      _c(
                                        "v-card-text",
                                        [
                                          _c("v-img", {
                                            staticClass: "mx-auto my-4",
                                            attrs: {
                                              src: `${_vm.assetsPath}/p/img/alpaca.png`,
                                              width: "90",
                                            },
                                          }),
                                          _c(
                                            "div",
                                            { staticClass: "text-center" },
                                            [
                                              _vm._v(
                                                "\n                    アルパカコネクトへようこそ！\n                    "
                                              ),
                                              _c("br"),
                                              _vm._v(
                                                "さぁ、一緒にPBWを楽しみましょう\n                  "
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "div",
                                        { staticClass: "px-8 mb-8" },
                                        [
                                          _c(
                                            "v-btn",
                                            {
                                              attrs: {
                                                large: "",
                                                to: "/",
                                                depressed: "",
                                                block: "",
                                                color: "primary",
                                              },
                                            },
                                            [_vm._v("ログイン")]
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                  ],
                                  2
                                ),
                              ],
                              1
                            ),
                            _c(
                              "v-fade-transition",
                              [
                                _vm.errorMsg
                                  ? _c(
                                      "v-alert",
                                      {
                                        staticClass: "mx-4",
                                        attrs: {
                                          transition: "slide-x-transition",
                                          dense: "",
                                          outlined: "",
                                          icon: "error_outline",
                                          type: "error",
                                        },
                                      },
                                      [_vm._v(_vm._s(_vm.errorMsg))]
                                    )
                                  : _vm._e(),
                              ],
                              1
                            ),
                            _vm.step != 4
                              ? _c(
                                  "v-card-actions",
                                  [
                                    _vm.step != 1
                                      ? _c(
                                          "v-btn",
                                          {
                                            attrs: {
                                              disabled: _vm.loading,
                                              text: "",
                                            },
                                            on: {
                                              click: function ($event) {
                                                _vm.step--
                                              },
                                            },
                                          },
                                          [_vm._v("戻る")]
                                        )
                                      : _vm._e(),
                                    _c("v-spacer"),
                                    _vm.step == 1
                                      ? _c(
                                          "v-btn",
                                          {
                                            staticClass: "font-weight-bold",
                                            attrs: {
                                              color: "primary",
                                              depressed: "",
                                              disabled: invalid,
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.nextpage(_vm.step)
                                              },
                                            },
                                          },
                                          [_vm._v("次へ")]
                                        )
                                      : _vm._e(),
                                    _vm.step == 3
                                      ? _c(
                                          "v-btn",
                                          {
                                            staticClass: "font-weight-bold",
                                            attrs: {
                                              color: "primary",
                                              depressed: "",
                                              disabled: invalid || _vm.loading,
                                            },
                                            on: { click: _vm.signUp },
                                          },
                                          [_vm._v("登録する")]
                                        )
                                      : _vm._e(),
                                  ],
                                  1
                                )
                              : _vm._e(),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]
            },
          },
        ]),
      }),
      _c("CommonDialog", {
        ref: "errorDialog",
        attrs: { title: "登録に失敗しました", text: _vm.errorDialogMsg },
        on: { alertDialogReturn: _vm.errorDialogReturn },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }